@import 'src/styles/responsive';

.parent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  &Title {
    font-style: normal;
    font-weight: 600;
    color: var(--dark-05);
    margin: 0;
    font-size: 32px;
    line-height: 40px;
  }

  &Description {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    color: #4e4e5a;
    margin: 8px 0 16px 0;
  }
}
