@import 'src/styles/responsive';

.cardContainer {
  display: flex;
  flex-direction: column;
  background: var(--light-01);
  border: 1px solid var(--light-02);
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  @include device(smallOnly) {
    max-width: calc(100vw - 50px);
    transform: none;
    transition: none;
  }

  .info {
    z-index: 2;
    top: 16px;
    gap: 8px;
    left: 14px;
    right: 14px;
    display: flex;
    position: absolute;
    justify-content: space-between;
  }

  .contentLocation {
    display: flex;
    align-items: center;
    column-gap: 3px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    padding: 5px 0 15px 0;
    color: var(--light-01);
    margin: 0;

    path {
      fill: white;
    }
  }

  &:hover .photo {
    transform: scale(1.1);
    transition: 0.8s ease-in-out;
  }

  .image {
    width: 100%;
    height: auto;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      z-index: 1;
    }
  }

  .photo {
    width: 100%;
    height: auto;
    max-height: 132px;
    object-fit: cover;
    transition: 0.8s ease-in-out;
    border-radius: 8px 8px 0 0;
    display: block;
  }

  .locationPin {
    width: 16px;
  }

  .content {
    padding: 16px 27px 18px 27px;
    border-bottom: 1px solid var(--light-02);
  }

  .bookingType,
  .bookingId {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .bookingId {
    text-transform: uppercase;
    color: var(--primary-60);
    word-wrap: break-word;
    text-align: right;
    font-size: 24px;
  }

  .bookingType {
    font-size: 24px;
    color: var(--light-01);
    text-align: left;

    &[data-isPromotion='true'] {
      text-transform: uppercase;
    }
  }

  .bookingInfo {
    display: flex;
    flex-direction: column;
  }

  .title {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--light-01);
  }

  .footer {
    display: flex;
    padding: 10px 27px 10px 27px;
    justify-content: space-between;
  }

  .footerDate {
    font-style: normal;
    font-weight: 500;
    color: var(--dark-05);
    line-height: 18px;
    font-size: 14px;
  }

  .footerDateType {
    display: block;
    line-height: 15px;
    font-size: 12px;
    color: var(--tertiary-100);
    padding: 0 0 3px 0;
  }

  .boxValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 16px;
    bottom: 16px;
    left: 16px;
    color: var(--light-01);
    z-index: 4;
    gap: 4px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;

    .valueContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .value {
      margin-left: 7px;
    }
  }
}
