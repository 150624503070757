@import 'src/styles/responsive';

.parent {
  &PaymentMethod {
    box-sizing: border-box;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light-01);
    border: 1px solid var(--light-03);
    border-radius: 8px;
    padding: 0 24px 0 24px;
    min-width: 276px;

    @include device(extraSmall) {
      min-width: 100%;
    }
  }

  &PaymentWithMargin {
    margin: 0 0 0 8px;
  }

  &PaymentDisabled {
    color: var(--dark-01);
    cursor: pointer;
    background-color: transparent;
  }

  &Skeleton h3 {
    margin: 0;
  }

  &Skeleton ul {
    margin: 0 !important;
  }

  &PaymentInfo {
    display: flex;
    column-gap: 16px;
    align-items: center;
  }

  &PaymentIcon {
    width: 47px;
    height: 32px;
  }

  &PaymentType {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: var(--dark-01) !important;
  }

  &EditLabel,
  &Date {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: var(--tertiary-100) !important;
  }

  &Date {
    width: min-content !important;
    color: var(--dark-04) !important;
  }

  &CurrentNumber {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: var(--dark-04);
  }
}
