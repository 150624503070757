@import 'src/styles/responsive';

.parent {
  &Form {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &Asterisk {
    margin: 0 0 0 5px;
    color: var(--warning);
  }

  &FormSubmit {
    display: flex;
    column-gap: 24px;
    padding-top: 20px;
    margin-left: auto;

    @include device(smallOnly) {
      width: 100%;
      flex-direction: column;
      gap: 15px;
    }
  }

  &FormCheckout {
    padding-top: 20px;
  }

  &SubmitButton {
    @include device(smallOnly) {
      width: 100% !important;
    }
  }

  &FormDeleteAction {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &FormActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -20px;

    @include device(smallOnly) {
      width: 100%;
      flex-direction: column;
    }
  }

  &FormRemoveButton {
    height: 100%;
    margin-top: 20px;
    align-items: center;
  }

  &RoomError {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    margin-bottom: 0;
    color: var(--tertiary-100);
    margin-top: -10px;
  }

  &TemperatureCheck {
    margin: 30px 0 0 0;
    display: inline-flex;
    justify-content: center;
    width: 100%;

    @include device(smallOnly) {
      margin: 12px 0 0 0;
    }
  }

  &DeleteButton:disabled {
    opacity: 0.2;
  }

  &FormDescriptionPhone {
    font-style: normal;
    font-weight: 600;
    font-size: 10px !important;
    color: var(--dark-05);
  }

  &PatientCode {
    box-sizing: border-box;
    color: var(--black);
    border-radius: 16px;
    width: max-content;
    padding: 2px 20px;
    background-color: var(--primary-20);
    border: 1px solid var(--primary-100);
    margin: 0 !important;
    @include device(smallOnly) {
      width: 100% !important;
    }

    span {
      font-weight: 700;
    }

    @include device(smallOnly) {
      width: 100%;
      text-align: center;
      border-radius: 6px;
    }
  }

  &NotifyButton {
    @include device(smallOnly) {
      width: 100% !important;
    }
  }

  &ManualCheckInButton {
    border-color: var(--tertiary-100) !important;
    @include device(smallOnly) {
      width: 100% !important;
    }
  }

  // &ModalHr {
  //   margin: 0 !important;
  // }

  &HR {
    margin: 0 !important;
  }

  &LastSMS {
    margin: 0;
  }

  &ErrorMessage {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--warning);

    @include device(smallOnly) {
      font-size: 12px;
    }
  }

  &BottomActions {
    display: flex;
    align-items: baseline;
    gap: 16px;
    @include device(smallOnly) {
      flex-direction: column;
      gap: 12px;
    }
  }

}

:global {
  label[for="temperature_bypass"] {
    margin-top: 0 !important;
  }
}

.modalContainer {
  box-sizing: border-box;
  min-width: 100%;
  background-color: red;
  @include device(stdSmUp) {
    min-width: 550px;
  }
}
