@import 'src/styles/responsive';

.promotion-calendar {
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  .main-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: minmax(auto, auto);
    box-sizing: border-box;

    .calendar-col {
      display: flex;
      flex-direction: column;
      min-width: 0;
      box-sizing: border-box;
    }
  }

  .header-cell {
    box-sizing: border-box;

    .header-date-label {
      display: block;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      color: var(--dark-06);
      background-color: var(--primary-20);

      border: 1px solid var(--light-03);

      &:not(:last-child) {
        border: 1px solid var(--light-03);
      }

      @include device(smallOnly) {
        white-space: pre;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .book-button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #0000001a;
      background-color: #7eb781cc;
      color: white;
      width: 100%;
      border-radius: 9px 9px 0 0;
      padding: 2px 0;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      &:disabled {
        background-color: var(--light-03);
        color: white;
        cursor: not-allowed;
      }

      @include device(smallOnly) {
        gap: 4px;
      }

      svg {
        @include device(extraMediumSmall) {
          display: none;
        }

        path {
          stroke: white;
        }
      }
    }
  }

  .body-col {
    box-sizing: border-box;

    .body-cell {
      box-sizing: border-box;

      background-color: white;
      border: 1px dashed var(--light-03);
      width: 100%;
      display: flex;
      column-gap: 2px;
      padding: 2px 1px;
      word-break: break-all;
      align-items: center;
      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: var(--primary-10);
          border-radius: 4px;
        }
      }

      @include device(smallOnly) {
        word-break: keep-all;
      }

      &:not(:last-child) {
        border: 1px dashed var(--light-03);
      }

      .empty-slot {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: var(--dark-01);
        padding: 4px;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .more {
        cursor: pointer;
        padding: 8px 4px;
        border-radius: 12px;
        background-color: var(--attention);
        color: white;
        font-size: 14px;
        font-weight: 500;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        text-align: center;
        user-select: none;
        margin: 0;

        @include device(extraMediumSmall) {
          padding: 8px 2px;
        }
      }

      .room {
        flex: 1;
        cursor: pointer;
        height: 100%;
        background-color: #e4f2e4;
        padding: 16px 8px;
        overflow: hidden;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        color: #477648;
        box-sizing: border-box;

        &[data-error='true'] {
          background-color: #f2e4e4;
          color: #c92f2f;
          white-space: pre-line;

          button {
            color: #c92f2f !important;
            background-color: #fbefef !important;
            border-radius: 8px !important;
          }
        }

        .room-name {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
        }

        .base-label {
          font-size: 12px;
          line-height: 15px;
          font-weight: 500;
        }
      }
    }
  }
}

.slot-details-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.slot-details {
  z-index: 10 !important;
  box-sizing: border-box;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  min-width: 250px;
  width: max-content;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    border-radius: 6px !important;
    box-shadow: 0 4px 416x rgba(78, 78, 90, 0.16);
  }

  .slot-container {
    padding: 8px 4px;
    box-sizing: border-box;

    &[data-error='true'] {
      .room-label {
        color: #c92f2f;
      }

      .room-date {
        color: #c92f2f;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--light-03) !important;
    }

    .remove-button {
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 6px;

      &:not(:disabled) {
        &:hover {
          background-color: var(--light-02);
          transition: 0.4s ease-in-out;
        }
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
      }
    }

    .room-label {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: var(--dark-06);
    }

    .room-date {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: var(--dark-06);
      margin-top: 6px;
    }
  }
}
