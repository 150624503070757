.tooltip {
  font-size: 12px;
  font-weight: normal;

  &Title {
    font-size: 14px;
    font-weight: 500;
  }

  &ActionItem {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  &Icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}