@import 'src/styles/responsive';

.parent {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px 0 0 0;

  p {
    @include device(extraMediumSmall) {
      white-space: break-spaces;
    }
  }
}

.hvacLabel {
  font-weight: 400;
  color: var(--tertiary-100);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  user-select: none;
  position: relative;
  &::after {
    content: '*';
    color: var(--tertiary-100);
    top: -2px;
    position: absolute;
  }
}

.hvacCheckbox {
  span {
    margin: 0;
  }
}

.hvacInfo {
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-05);
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  position: relative;
  margin: 0 0 0 8px;
  &::before {
    content: '*';
    top: 0px;
    left: -8px;
    position: absolute;
  }
}
