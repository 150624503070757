@import 'src/styles/responsive';

.parent {
  :global {
    .ant-select-selector {
      height: 26px !important;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    padding: 24px 0 8px 0;
    row-gap: 8px;
  }

  &ActionContainer {
    margin-bottom: 8px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
    @include device(stdMdUp) {
      align-items: center;
      flex-direction: row;
    }
  }

  &HeadSelect {
    width: 76px;

    :global {
      .ant-select-selector {
        height: 26px !important;
      }
    }
  }

  &Skeleton {
    margin-bottom: 16px;
    width: 300px !important;
    height: 70px !important;
  }

  &Sticky {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  }

  &EmptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    row-gap: 32px;
  }

  &EmptyLink {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 24px;
    line-height: 32px;
    color: var(--tertiary-100);
    font-weight: 600;
    text-align: center;

    &:hover {
      color: var(--tertiary-100);
    }
  }

  &HeadButton {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &HeadContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
  }

  &HeadContentRight {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;
  }


  &HeadContent,
  &HeadContentSelect {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  &HeadContentSelect {
    column-gap: 8px;
  }

  &HeadInfo {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--dark-04);
  }
}

.message {
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--dark-05);
  line-height: 18px;
  font-weight: 500;
  display: flex;

  @include device(smallOnly) {
    text-align: justify;
    row-gap: 12px;
    flex-direction: column;
  }

  &Action {
    color: var(--tertiary-100);
    display: block;
    cursor: pointer;
    margin-left: 4px;

    @include device(smallOnly) {
      margin: 0;
    }
  }
}
