.parent {
  height: auto;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 12px;

  &Header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &HeaderRight {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &PatientNumberContainer {
    background: var(--light-02);
    width: 38px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    gap: 2px;
  }

  &PatientNumber {
    color: var(--dark-04);
    font-weight: 500;
    font-size: 14px;
    user-select: none;
  }

  &PatientNumberIcon {
    width: 16px;
    height: 16px;
  }

  &RoomName {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: var(--dark-06);
    margin: 0;
    white-space: break-spaces;
    text-align: justify;
  }

  &RoomSelect {
    width: 100%;
    text-align: left;
  }
}
