.parent {
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    margin: 10px 0 10px 0;
    transition: transform 0.1s;
  }

  button:hover {
    cursor: pointer;
    transform: scale(0.95);
  }

  button:active {
    transform: scale(1.1);
  }
}
