@import 'src/styles/responsive';

.parent {
  &Sticky {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  }

  &HeadContentSelect {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &HeadInfo {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--dark-04);
  }

  &EmptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    row-gap: 32px;
  }

  &EmptyLink {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-tertiary-100);
    font-weight: 600;
    text-align: center;

    &:hover {
      color: var(--color-tertiary-100);
    }
  }

  &ActionContainer {
    margin-bottom: 8px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
    @include device(stdMdUp) {
      align-items: center;
      flex-direction: row;
    }
  }

  &HeadSelect {
    width: 76px;

    :global {
      .ant-select-selector {
        height: 26px !important;
      }
    }
  }

  &HeadButton {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.skeletonButton {
  width: 300px !important;
  height: 70px !important;
  margin-bottom: 16px;
}
