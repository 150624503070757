.announceButtonContainer {
  button,
  span {
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
  }

  button {
    width: max-content !important;
    min-width: 138px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.announceIconButton {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  padding: 4px;
  background-color: var(--success) !important;
  border-color: var(--success) !important;
  &:hover {
    background-color: var(--success) !important;
    border-color: var(--success) !important;
  }
}

.announceModal {
  max-height: 80vh;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 32px;
  width: 300px;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.announceFormButtonContainer {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.announceForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
