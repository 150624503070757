@import 'src/styles/responsive';

.container {
  user-select: none;
  width: 100%;
  max-width: max-content;
  font-family: 'Inter';
}

.popoverContainer {
  z-index: 100 !important;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  @include device(smallOnly) {
    gap: 8px;
  }
}

.actionButton {
  box-sizing: border-box;
  background-color: transparent;
  border: 2px solid var(--dark-04);
  height: 42px;
  width: 42px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &[disabled] {
    border-color: var(--dark-03);
    cursor: not-allowed;
    opacity: 0.5;
  }

  path {
    fill: var(--tertiary-100);
  }

  @include device(smallOnly) {
    height: 32px;
    width: 32px;
  }
}

.rotate {
  transform: rotate(180deg);
}

.dateCell {
  position: relative;
  box-sizing: border-box;
  height: 70px;
  width: 66px;
  border-radius: 6px;
  cursor: pointer;
  background-color: var(--primary-100);
  color: var(--dark-05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &[disabled] {
    cursor: not-allowed;
  }

  &[data-disabled='true'] {
    border: 1px dashed var(--dark-05);
    background-color: var(--light-05);

    .dayNumber {
      color: var(--dark-03);
    }

    .dayText {
      color: var(--dark-03);
    }
  }

  &[data-active='true'] {
    background-color: var(--tertiary-100);
    color: var(--white);

    .dayNumber {
      color: var(--white);
    }

    .dayText {
      color: var(--white);
    }
  }

  &[data-current='true'] {
    &:after {
      content: '';
      position: absolute;
      top: -4px;
      right: -5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--success);
    }
  }

  @include device(smallOnly) {
    height: 56px;
    width: 40px;
    padding: 4px;
  }
}

.dayNumber {
  color: var(--dark-05);
  font-size: 24px;
  line-height: 32px;
  font-weight: 900;
  margin: 0;

  @include device(smallOnly) {
    font-size: 16px;
    line-height: 24px;
  }
}

.dayText {
  color: var(--dark-05);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0;

  @include device(smallOnly) {
    font-size: 12px;
    line-height: 16px;
  }
}

.calendarIcon {
  box-sizing: border-box;
  height: 70px;
  width: 66px;
  border-radius: 6px;
  cursor: pointer;
  background-color: var(--light-02);
  display: flex;
  justify-content: center;
  align-items: center;

  @include device(smallOnly) {
    display: none;
  }
}

.monthContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;

  @include device(smallOnly) {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    &:not(.mobileHeader *) {
      display: none;
    }
  }
}

.monthLabel {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  margin: 0;
}

.dateLabel {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
}

.skeleton {
  width: min(500px, 100%) !important;
  height: 66px !important;
}

.mobileCalendarIcon {
  display: none;

  @include device(smallOnly) {
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 6px;
    cursor: pointer;
    background-color: var(--light-02);
    justify-content: center;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.mobileHeader {
  display: none;
  @include device(smallOnly) {
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
  }
}
