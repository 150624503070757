.name {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: var(--dark-05);
  margin: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.level {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--dark-05);
  margin: 0;
}

.skeleton {
  span {
    height: 62px !important;
    width: 250px !important;
  }
}

.levelContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.levelValue {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--dark-02);
  margin: 0;
}

.masterAccessContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.masterAccessText {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--dark-05);
  margin: 0;
}

.masterAccessTextValue {
  color: var(--dark-02);
}
